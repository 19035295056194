// Dependências
import { Helmet } from 'react-helmet';

// CSS
import '../css/Home.css';

// Componentes
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const whatsappURL = "https://api.whatsapp.com/send/?phone=5519997547928&text&type=phone_number&app_absent=0";

function Home() {
  return (
    <div className="Home">
      <Helmet>
        <title>Escritório Brasil - Charqueada</title>
        <meta
          name="description"
          content="Escritório de contatilidade empresarial que atende Charqueada e região."
        />
      </Helmet>

      <Navbar page="/" />
      <main>
        <div className="title">
        <h1>
          CRESPILHO CONTABILIDADE EMPRESARIAL LTDA
        </h1>
        <h2>
          <span>(ESCRITÓRIO CONTÁBIL BRASIL)</span> <br />
          ATENDEMOS CHARQUEADA E REGIÃO!
        </h2>
        </div>

        <div 
          className="chamaNoZap" 
          onClick={() => window.open(whatsappURL, "_blank")}>
            <img
              src="/images/whatsapp-alt.png"
              title="Ícone whatsapp"
              alt="Entre em contato"
            />
            <strong>Entre em contato!</strong>
        </div>
      </main>

      <Footer />
    </div>
  );
}

export default Home;
