
// CSS
import '../css/Footer.css';

function Footer() {

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <footer className='footer'>
      <div className='info'>
        <div className='left'>
          <div>
            <img
              src="/images/whatsapp.png"
              title="Whatsapp"
              alt="Ícone do Whatsapp"
            />
            <strong
              className='contact-info'
              onClick={() => copyToClipboard("199997547928")}>
              (19) 9997547928
            </strong>
          </div>
          <div>
            <img
              src="/images/telefone.png"
              title="Telefone"
              alt="Ícone de telefone"
            />
            <div>
              <strong
                className='contact-info'
                onClick={() => copyToClipboard("1934861584")}>
                (19) 3486-1584
              </strong>

              <br />

              <strong
                className='contact-info'
                onClick={() => copyToClipboard("1934862328")}>
                (19) 3486-2328
              </strong>
            </div>
          </div>
          <div>
            <img 
              src="/images/email.png"
              title="Email"
              alt="Ícone do Email"
            />
            <strong
              className='contact-info'
              onClick={() => copyToClipboard("escritoriobrasil@yahoo.com.br")}>
              escritoriobrasil@yahoo.com.br
            </strong>
          </div>
        </div>

        <ul className='right'>
          <li><a href='/'>INÍCIO</a></li>
          <li><a href='/sobre'>SOBRE</a></li>
          <li><a href='/contato'>CONTATO</a></li>
        </ul>
      </div>

      <div className="copyright">
        <h2>Escritório Contábil Brasil</h2>
        <strong>Crespilho Contabilidade Empresarial LTDA 2024 ©</strong>
      </div>
    </footer>
  );
}

export default Footer;
