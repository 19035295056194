// Dependências
import { Helmet } from 'react-helmet';

// Componentes
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

// CSS
import '../css/Contato.css';

const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
};

function Contato() {
  return (
    <div className="Contato">
      <Helmet>
        <title>Contato - Escritório Brasil</title>
        <meta
          name="description"
          content="Contato e localização do Escritório Contábil Brasil, Charqueada - SP." />
      </Helmet>

      <Navbar page="/contato" />

      <main>
        <div className="info">
          <h1>Contato:</h1>

          <div>
            <img
              src="/images/whatsapp.png"
              title="Whatsapp"
              alt="Ícone do Whatsapp"
            />
            <strong
              className='contact-info'
              onClick={() => copyToClipboard("199997547928")}>
              (19) 9997547928
            </strong>
          </div>

          <div>
            <img
              src="/images/telefone.png"
              title="Telefone"
              alt="Ícone de telefone"
            />
            <div>
              <strong
                className='contact-info'
                onClick={() => copyToClipboard("1934861584")}>
                (19) 3486-1584
              </strong>

              <br />

              <strong
                className='contact-info'
                onClick={() => copyToClipboard("1934862328")}>
                (19) 3486-2328
              </strong>
            </div>
          </div>
          
          <div>
            <img
              src="/images/email.png"
              title="Email"
              alt="Ícone do Email"
            />
            <strong
              className='contact-info'
              onClick={() => copyToClipboard("escritoriobrasil@yahoo.com.br")}>
              escritoriobrasil@yahoo.com.br
            </strong>
          </div>

          <div>
            <img 
              src="/images/localizacao.png"
              title="Localização"
              alt="Ícone da localização"
            />
            <strong
              className="contact-info"
              onClick={() => copyToClipboard("Rua 1° de Maio, Centro, Charqueada - SP")}>
              Rua 1° de Maio, Centro, Charqueada - SP
            </strong>
          </div>
        </div>

        <div className="location">
          <h2>Localização</h2>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3685.8771963592676!2d-47.77993022583247!3d-22.508790223766216!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c7b96eb36721e3%3A0x2cfb435747a4b528!2sEscrit%C3%B3rio%20Cont%C3%A1bil%20Brasil!5e0!3m2!1spt-BR!2sbr!4v1713520458065!5m2!1spt-BR!2sbr"
            className="map"
            title="map"
            allowfullscreen="true"
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade">
          </iframe>
        </div>
      </main>

      <Footer />
    </div>
  )
}

export default Contato;
