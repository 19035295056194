// Dependências
import { useState } from 'react';

// CSS
import '../css/Navbar.css';

function Navbar({ page }) {
  const [isMenuOpen, changeMenu] = useState(false);

  return (
    <header className='navbar'>
      <img
        src="/images/icon.png"
        title="Ícone Escritório Brasil"
        alt="Escritório Brasil" />
      <div
        className="menu-button"
        onClick={() => changeMenu(!isMenuOpen)}>
        <img
          src={`/images/${isMenuOpen ? "fechar-menu" : "menu"}.png`}
          width="20" height="20"
          title="Ícone menu"
          alt="Botão da barra de navegação."
          style={{
            display: window.innerWidth < 650 ? "inline" : "none"
          }} 
        />
      </div>
      <nav
        style={{
          display: isMenuOpen || window.innerWidth >= 650 ? "flex" : "none"
        }}>
        <ul>
          <li>
            <a href="/"
              className={page === "/" ? "active" : ""}>
              INÍCIO
            </a>
          </li>

          <li>
            <a href="/sobre"
              className={page === "/sobre" ? "active" : ""}>
              SOBRE
            </a>
          </li>
          
          <li className="dropdown">
            <div className="dropdown-btn">SERVIÇOS PRESTADOS</div>
            <div className="dropdown-content">
              <strong>Abertura de empresas</strong>
              <strong>Encerramento de empresas</strong>
              <strong>Dépto fiscal, contábil e pessoal</strong>
              <strong>Imposto de renda</strong>
              <strong>MEI</strong>
            </div>
          </li>

          <li>
            <a href="/contato"
              className={page === "/contato" ? "active" : ""}>
              CONTATO
            </a>
          </li>
        </ul>
      </nav>
    </header>
  )
}

export default Navbar;
