// Dependências
import { Helmet } from 'react-helmet';

// Componentes
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

// CSS
import '../css/Sobre.css';

function Sobre() {
  return (
    <div className="Sobre">
      <Helmet>
        <title>Sobre - Escritório Brasil</title>
        <meta
          name="description"
          content="Sobre o Escritório Contábil Brasil, Charqueada - SP." />
      </Helmet>

      <Navbar page="/sobre" />

      <main>
        <div className="title">
          <h1>
            CRESPILHO CONTABILIDADE EMPRESARIAL LTDA
          </h1>
          <h2>
            (ESCRITÓRIO CONTÁBIL BRASIL)
          </h2>
        </div>

        <div>
          <h2>Nossa história:</h2>
          <p>
            Fundado em Maio de 1995, pelo Sr. Antônio José Crespilho, atualmente vem
            progredindo com a administração das suas filhas Graziela e Rejane.
          </p>

          <img
            src="/images/sobre.jpeg"
            name="Administradores da empresa"
            alt="Administradores do Escritório Contábil Brasil." />
        </div>

        <div>
          <h2>Missão, valores e visão:</h2>

          <p>
            <strong>Missão: </strong>
            Clientes satisfeitos, orientar e dar assessoria para prosperar e se manter no mercado de trabalho.
          </p>

          <p>
            <strong>Valores: </strong>
            Nosso progresso deve-se ao bom atendimento com responsabilidade e honestidade.
          </p>

          <p>
            <strong>Visão: </strong>
            O Sucesso do nosso cliente é o nosso sucesso também!
          </p>
        </div>

        <div>
          <h2>Serviços:</h2>
          <p>
            Prestamos serviços de contabilidade, fiscal, departamento pessoal,
            imposto de renda, abertura, alteração, regularização e encerramento de empresas.
          </p>
        </div>

        <div>
          <h2>
            Atendemos Charqueada e região <br />
            Venha nos fazer uma visita!
          </h2>
        </div>
      </main>

      <Footer />
    </div>
  )
}

export default Sobre;