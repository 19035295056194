// Dependências

// CSS
import '../css/404.css';

function NotFound() {
  return (
    <div className="NotFound">
      <h2>404 - PÁGINA NÃO ENCONTRADA!</h2>
      <a href="/"><strong>VOLTAR AO INÍCIO</strong></a>
    </div>
  )
}

export default NotFound;